import "./DesktopHeader.css";
import Logo from "../../assets/Logo";
import { UserMenu } from "../UserMenu/UserMenu";
import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import SelectCarrera from "../SelectCarrera/SelectCarrera";

const DesktopHeader = () => {
  const { carreraActual } = useSelector(selectStudent);

  return (
    <div className="desktop-header">
        <div className="desktop-header-info-logo">
          <div className="header-logo-container">
          <Logo />
          </div>
          <SelectCarrera/>
        </div>
        <div className="desktop-header-info-data">
          <UserMenu />
        </div>
    </div>
  );
};

export default DesktopHeader;
