import "./UserMenu.css";
import { useSelector } from "react-redux";
import { selectStudent, setToken } from "../../reduxSlices/studentSlice";
import { useMsal } from "@azure/msal-react";

import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlDropdown from "@shoelace-style/shoelace/dist/react/dropdown";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useAppDispatch } from "../../app/store";

export const UserMenu = () => {
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const { completeName } = useSelector(selectStudent);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(setToken(""));
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  return (
    <div className="header-info-data-user">
      {/* <p className="header-info-data-user-name">
        {completeName.lastName ? completeName.lastName + ", " + completeName.name : ""}
        </p> */}
      <p className="header-info-data-user-name-circle">{completeName.name.charAt(0)}</p>
      <SlDropdown>
        <SlIconButton slot="trigger" className="list" name="chevron-down" label="Menu"></SlIconButton>
        <SlMenu style={{ width: "180px" }}>
          <SlMenuItem value="logout" onClick={handleLogout}>
            <span className="menu-item">Cerrar sesion</span>
            <SlIcon slot="suffix" name="box-arrow-right"></SlIcon>
          </SlMenuItem>
        </SlMenu>
      </SlDropdown>
    </div>
  );
};
