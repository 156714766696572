import { useEffect, useState } from "react";
import "./DesktopSubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { LiaSpinnerSolid } from "react-icons/lia";
import {
  ICalendarSubjects,
  IDesktopCalendarSubjects,
  IOfferedReserveData,
  IOfferedSubjectData,
  IQuota,
  ISelectedSection,
} from "../../types";
import { getQuotaColor } from "../../utils";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { BsFillPinAngleFill } from "react-icons/bs";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsDemoCount,
  selectCalendarSubjectsList,
  selectInscribedSubjects,
  selectSubcriptionProcessSectionIds,
  setDetailSubjectOffered,
  setPinnedSubjectsList,
  addSubProcessSectionIds,
  subtractCalendarSubjectDemoCount,
  removeSubProcessSectionIds,
} from "../../reduxSlices/subjectsSlice";
import { useAppDispatch } from "../../app/store";
import {
  selectFlags,
  selectInTransitInRquest,
  selectMobileFeatures,
  setDesktopTab,
  setLastCalendarAdd,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import { useToastError } from "../../hooks/useToast";
import Divider from "../Divider/Divider";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import { changeSection } from "../../Api/reservation";
import { useNavigate } from "react-router-dom";

interface IPropsSubjectItem {
  id: number;
  subject: string;
  subjectId: string;
  section: string;
  selected: boolean;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange: (data: ISelectedSection) => Promise<any>;
  disabled: boolean;
  pinned: boolean;
  creditos: number;
  sectionType: string;
  idMateria: string;
}

const filtrarCasosRepetidos = (cursos: any[]) => {
  let uniqueCourses: any = [];
  cursos.forEach((curso: any) => {
    const existe = uniqueCourses.some(
      (uniqueCurso: any) => uniqueCurso.dia === curso.dia && uniqueCurso.desde === curso.desde
    );
    if (!existe) {
      uniqueCourses.push(curso);
    }
  });
  return uniqueCourses;
};

const DesktopSubjectItem = ({
  subject,
  section,
  id,
  selected,
  subjectData,
  quota,
  onChange,
  subjectId,
  disabled,
  pinned,
  creditos,
  sectionType,
  idMateria,
}: IPropsSubjectItem) => {
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const { collapseCards, collapseCardsSinCupo } = useSelector(selectFlags);
  const dispatch = useAppDispatch();
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [sendInscription, setSendInscription] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const [inscriptionConfirmed, setInscriptionConfirmed] = useState(false);
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);

  const showCupoInfo = process.env.REACT_APP_ENV === "Test" || process.env.REACT_APP_ENV === "Localhost";

  const showError = useToastError();
  const navigate = useNavigate();

  const subType = sectionType === "OPTATIVO" ? "Optativa" : "Obligatoria";
  const materiasList = inscribedSubjects?.map((s) => s.id) || [];
  const cambioSeccion = materiasList.indexOf(idMateria) > -1;

  useEffect(() => {
    return () => {
      dispatch(removeSubProcessSectionIds(id));
    };
  }, []);

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setSendInscription(true);
      setTimeout(() => {
        setHasClick(false);
        setSendInscription(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };

  const handleToggleCalendarSubject = async (idCurso: number, reserveData: IOfferedReserveData) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subjectId),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
          tipoCursada: horario.values.tipoClase,
        };

        listado.push(newDemo);
      });
    });
    listado = filtrarCasosRepetidos(listado);
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));

    // creado para hacer el scroll into view de la tarjeta
    dispatch(setLastCalendarAdd(String(idCurso)));
    // if MobileCalendario, go to calendar
    if (mobileFeatures) {
      navigate("/calendario");
    } else {
      dispatch(setDesktopTab("calendario"));
    }
  };

  const handleModalPanelInfo = (subjectId: number, cursoId: number) => {
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
    dispatch(setPanelFilters(false));
  };

  const handlTryReserve = async (idCurso: number) => {
    setSendInscription(true);
    dispatch(addSubProcessSectionIds(idCurso));
    const result = await onChange({
      tic: subjectData.reserveData.tic,
      tim: subjectData.reserveData.tim,
      idC: subjectData.reserveData.idC,
      idA: subjectData.reserveData.idA,
      idS: subjectData.reserveData.idS,
      section,
      subjectId: subjectId,
    });
    if (result && result.responseCode && result?.responseCode === 200) {
      // Quitar de la oferta visible
      // setInscriptionConfirmed(true)
    } else {
      setSendInscription(false);
      console.log("REsult: ",result);
      //TODO: set animation cancelled
      
    }
  };

  const handlTryChangeSection = async (idCurso: number) => {
    setSendInscription(true);
    dispatch(addSubProcessSectionIds(idCurso));
    const result = await changeSection({
      tic: subjectData.reserveData.tic,
      tim: subjectData.reserveData.tim,
      idC: subjectData.reserveData.idC,
      idA: subjectData.reserveData.idA,
      idS: subjectData.reserveData.idS,
    });

    if (result.data.responseCode !== 200) {
      setSendInscription(false);
      showError(result.data.responseMessage);
    }
    // dispatch(setSubProcessSectionIds(id)); //toggle
  };

  return (
    <div
      className={`desktop-subject-item ${pinned ? "desktop-pinned-subject" : ""} ${
        quota && quota.availability === 0 ? "bg-gray-noquota" : ""
      } ${inscriptionConfirmed ? "hidden-card" : ""}`}
    >
      <div className="animation-wrapper">
        <div
          className={`${
            sendInscription && subcriptionProcessSectionIds.indexOf(Number(id)) !== -1 ? "fill-animation":" "
          }`}
        ></div>
        <div style={{zIndex:1}} className="desktop-item-first-row">
          {quota && quota?.qty >= 0 && student.prioridad && (
            <>
              <FaUser
                title={
                  subjectData.reserveData.tic && subjectData.reserveData.tim
                    ? "Cupo: " +
                      String(subjectData.reserveData.tic + " " + subjectData.reserveData.tim) +
                      "   |   Cuota: " +
                      String(quota.qty || "-") +
                      `   |   idCurso:  ${subjectData.reserveData.idS}` +
                      `   |   idMateria:  ${idMateria}` +
                      `   |   idCupo:  ${subjectData.reserveData.idC}`
                    : "sin info"
                }
                style={{ color: quota ? getQuotaColor(quota.availability) : "black" }}
                className={`desktop-icon-user-quota ${showCupoInfo ? "desktop-icon-user-quota-hover" : ""}`}
              />
            </>
          )}
          <p
            className={`desktop-item-title ${quota && quota.availability === 0 ? "desktop-item-title-noquota" : ""}`}
            onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
          >
            {section}
          </p>
          <div className="desktop-items-actions">
            <div className="item-actions-circles">
              {pinned && <p className="subject-item-type">{subType}</p>}
              <button
                className="desktop-action btn-pin"
                title="Fijar materia"
                onClick={() => handlePinSubject(id, pinned)}
              >
                <BsFillPinAngleFill />
              </button>
              {student.mostrarHorarios && (
                <button
                  className={`desktop-action ${
                    calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id)
                      ? "btn-calendar-active"
                      : ""
                  } ${calendarLoading ? "calendar-loading-icon" : ""}`}
                  title="Visualizar en calendario"
                  // onClick={() => handleToggleCalendarSubject(id)}
                  onClick={() => handleToggleCalendarSubject(Number(id), subjectData.reserveData)}
                  disabled={calendarLoading}
                >
                  <SlIcon name="calendar-event"></SlIcon>
                </button>
              )}
            </div>
            {student.prioridad && quota && quota.availability === 0 && (
              <button className="desktop-action-add-noquota" disabled>
                <SlIcon name="x"></SlIcon>
                Sin cupo
              </button>
            )}
            {student.prioridad && quota && quota.availability > 0 && (
              <button
                style={{ width: `${cambioSeccion ? "165px" : ""}` }}
                className={`desktop-action-add ${sendInscription ? "desktop-action-add-disabled" : ""}`}
                onClick={cambioSeccion ? () => handlTryChangeSection(Number(id)) : () => handlTryReserve(Number(id))}
                disabled={sendInscription}
              >
                {sendInscription && subcriptionProcessSectionIds.indexOf(Number(id)) !== -1 ? (
                  <>
                    <LiaSpinnerSolid className="spinner-animation" /> Procesando
                  </>
                ) : (
                  <>
                    {cambioSeccion ? (
                      <>
                        <SlIcon name="arrow-left-right"></SlIcon>
                        Cambiar sección
                      </>
                    ) : (
                      <>
                        <SlIcon name="plus"></SlIcon>
                        Inscribirme
                      </>
                    )}
                  </>
                )}
              </button>
            )}
          </div>
        </div>
        <div
          className={`${
            collapseCards
              ? "hide-collapse-info"
              : collapseCardsSinCupo && quota && quota.availability === 0
              ? "hide-collapse-info"
              : ""
          } bottom-car-section`}
        >
          <div className="divider-card"></div>
          <div className="desktop-item-second-row">
            <ProfessorAndSubjectHours subjectData={subjectData} isDrawerContent={false} />
            {creditos > 0 ? (
              <div className="desktop-inner-col">
                <h4 className="ph-h4-title">Créditos</h4>
                <p>{creditos}</p>
              </div>
            ) : (
              <div className="desktop-inner-col"></div>
            )}
          </div>
        </div>
        <div className="mobile-items-actions" style={{zIndex:1}}>
          {pinned && <p className="subject-item-type">{subType}</p>}
          <button className="desktop-action btn-pin" title="Fijar materia" onClick={() => handlePinSubject(id, pinned)}>
            <BsFillPinAngleFill />
          </button>
          {student.mostrarHorarios && (
            <button
              className={`desktop-action ${
                calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id) ? "btn-calendar-active" : ""
              } ${calendarLoading ? "calendar-loading-icon" : ""}`}
              title="Visualizar en calendario"
              // onClick={() => handleToggleCalendarSubject(id)}
              onClick={() => handleToggleCalendarSubject(Number(id), subjectData.reserveData)}
              disabled={calendarLoading}
            >
              <SlIcon name="calendar-event"></SlIcon>
            </button>
          )}
          {student.prioridad && quota && quota.availability === 0 && (
            <button className="desktop-action-add-noquota" disabled>
              <SlIcon name="x"></SlIcon>
              Sin cupo
            </button>
          )}
          {student.prioridad && quota && quota.availability > 0 && (
            <button
              style={{ width: `${cambioSeccion ? "165px" : ""}`}}
              className={`desktop-action-add ${sendInscription ? "desktop-action-add-disabled" : ""}`}
              onClick={cambioSeccion ? () => handlTryChangeSection(Number(id)) : () => handlTryReserve(Number(id))}
              disabled={sendInscription}
            >
              {sendInscription && subcriptionProcessSectionIds.indexOf(Number(id)) !== -1 ? (
                <>
                  <LiaSpinnerSolid className="spinner-animation" /> Procesando
                </>
              ) : (
                <>
                  {cambioSeccion ? (
                    <>
                      <SlIcon name="arrow-left-right"></SlIcon>
                      Cambiar sección
                    </>
                  ) : (
                    <>
                      <SlIcon name="plus"></SlIcon>
                      Inscribirme
                    </>
                  )}
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopSubjectItem;
