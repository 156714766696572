import { useState, KeyboardEvent } from "react";
import "./SearchInput.css";
import { useAppDispatch } from "../../app/store";
import { selectSearchSubjectsValue, setSearchSubjectsValue } from "../../reduxSlices/subjectsSlice";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const SearchInput = () => {
  const [focus, setFocus] = useState(false);
  const dispatch = useAppDispatch();
  const searchValue = useSelector(selectSearchSubjectsValue);

  const setSearchValue = async (value: string) => {
    await dispatch(setSearchSubjectsValue(value));
  };

  const handleFocus = (bool: boolean) => {
    setFocus(bool);
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClearSearchAndInput = () => {
    setSearchValue("");
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <form onKeyDown={handleKeyDown} className={`search-container ${focus ? "input-focus-border" : ""}`}>
      <SlIcon name="search" className="search-icons"></SlIcon>
      <input
        className="search-input"
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        placeholder={""}
      />
      {searchValue?.length > 0 && (
        <button className="clear-search" onClick={handleClearSearchAndInput}>
          <SlIcon name="x" className="search-icons"></SlIcon>
        </button>
      )}
    </form>
  );
};

export default SearchInput;
