import "./BannerPrioridad.css";
import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useState } from "react";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export default function BannerPrioridad() {
  const { prioridad, mensajePrioridad } = useSelector(selectStudent);
  const [bannerPrioridad, setBannerPrioridad] = useState(true);

  const handleBannerPrioridad = (bool: boolean) => {
    setBannerPrioridad(bool);
  };

  if (!bannerPrioridad) {
    return null;
  }

  return (
    <div className={`desktop-mensaje-prioridad ${prioridad ? "prioridad-activa" : ""}`}>
      <span className="desktop-mensaje-prioridad-icon">
        <SlIcon name="exclamation-lg"></SlIcon>
      </span>
      <p>{mensajePrioridad}</p>
      <SlIcon className="close-mobile-size" name="x-lg" onClick={() => handleBannerPrioridad(false)} style={{ cursor: "pointer" }}></SlIcon>
    </div>
  );
}
