import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import "./MobileNav.css";
import { Link } from "react-router-dom";

const MobileNav = () => {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  return (
    <div className="mobile-nav">
      <h2 className="mobile-inscripciones-title">Inscripciones</h2>
      <Link to="/mis-cursos" className="mobile-nav-link">
        Mis cursos <span>{!inscribedSubjects ? 0 : inscribedSubjects.length}</span>
      </Link>
    </div>
  );
};

export default MobileNav;
