import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "../components/Calendar/Calendar";
import DesktopOfferList from "../components/DesktopOfferList/DesktopOfferList";


const HomePage = () => {
  const navigate = useNavigate();
  
  const token = localStorage.getItem("token");
  
  useEffect(() => {

    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }

  }, [])

  return (
    <div style={{ height: "100%", display:"flex", flexDirection:"column",gap:"10px" }}>
      <DesktopOfferList />
    </div>
  );
};

export default HomePage;
