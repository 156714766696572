import { toast } from "react-toastify";
import { stripHtml } from "string-strip-html";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export const useToastError = () => {
  const showError = (message: string, onClose?: () => void) => {
    const mHtml = stripHtml(message || "").result;
    toast.error(mHtml, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
    });
  };

  return showError;
};

export const useToastInfo = () => {
  const showInfo = (message: string, onClose?: () => void) => {
    const mHtml = stripHtml(message || "").result;
    toast.info(mHtml, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #00C4B3",
        padding: "10px 30px",
        minWidth: "350px"
      },
    });
  };

  return showInfo;
};
export const useToastSuccess = () => {
  const showSuccess = (message: string, onClose?: () => void) => {
    const mHtml = stripHtml(message || "").result;
    toast.success(mHtml, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #00C4B3",
        padding: "10px 20px",
        fontSize: "14px",
        color: "#424242",
      },
      icon: <SlIcon name="check2" style={{ background: "#00C4B3", color: "white", borderRadius: "100vh",padding:"3px",width:"14px",height:"14px" }}></SlIcon>,
    });
  };

  return showSuccess;
};

export const showToastWarning = (message: string, onClose?: () => void) => {
  const mHtml = stripHtml(message || "").result;
  toast.warning(mHtml, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    onClose: onClose,
  });
};
