import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "../components/Calendar/Calendar";
import DesktopOfferList from "../components/DesktopOfferList/DesktopOfferList";
import DesktopSelectedSubjects from "../components/DesktopSelectedSubjects/DesktopSelectedSubjects";

const MobileCursos = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="mobile-cursos-box">
        <div className="mobile-theader-cursos">
          <h5>Materia</h5>
          <h5>Desinscripción</h5>
        </div>
        <DesktopSelectedSubjects />
      </div>
    </div>
  );
};

export default MobileCursos;
