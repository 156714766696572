import { useEffect, useState } from "react";
import DesktopOfferList from "../DesktopOfferList/DesktopOfferList";
import DesktopSelectedSubjects from "../DesktopSelectedSubjects/DesktopSelectedSubjects";
import "./DesktopMain.css";
import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import { selectStudent } from "../../reduxSlices/studentSlice";
import FlexCalendar from "../FlexCalendar/FlexCalendar";
import BannerPrioridad from "../BannerPrioridad/BannerPrioridad";
import { useAppDispatch } from "../../app/store";
import { selectFlags, setDesktopTab } from "../../reduxSlices/globalFlagsSlice";

function DesktopMain() {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const student = useSelector(selectStudent);
  const { desktopTab } = useSelector(selectFlags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (student.mostrarHorarios) {
      dispatch(setDesktopTab("calendario"));
    } else {
      dispatch(setDesktopTab("materias"));
    }
  }, [student.mostrarHorarios]);

  const handleDesktopTab = (tab: "calendario" | "materias") => {
    dispatch(setDesktopTab(tab));
  };

  return (
    <div className="desktop-main">
      <div className="desktop-left">
        <h2 className="inscripciones-title">Inscripciones</h2>
        <div className="inner-left-box">
          <div className="desktop-inscribed-calendar-tab">
            <button
              className={`desktop-left-tab ${desktopTab === "materias" ? "desktop-left-tab-active" : false}`}
              onClick={() => handleDesktopTab("materias")}
            >
              Mis cursos
              <p className="desktop-inscribed-quantity">{!inscribedSubjects ? 0 : inscribedSubjects.length}</p>
            </button>
            {student.mostrarHorarios && (
              <button
                className={`desktop-left-tab ${desktopTab === "calendario" ? "desktop-left-tab-active" : false}`}
                onClick={() => handleDesktopTab("calendario")}
              >
                Calendario
              </button>
            )}
          </div>
          {desktopTab === "materias" && <DesktopSelectedSubjects />}
          {desktopTab === "calendario" && student.mostrarHorarios && <FlexCalendar />}
        </div>
      </div>
      <div className="desktop-right">
        <BannerPrioridad />
        <DesktopOfferList />
      </div>
    </div>
  );
}

export default DesktopMain;
