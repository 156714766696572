import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import "./MobileMenu.css";
import { Link, useLocation } from "react-router-dom";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const MobileMenu = () => {
  const location = useLocation();
  return (
    <div className="mobile-menu">
      <Link
        to="/calendario"
        className={`mobile-menu-link ${location.pathname === "/calendario" ? "mobile-menu-link-active" : ""}`}
      >
        <SlIcon name="calendar"></SlIcon>
        <span>Calendario</span>
      </Link>
      <Link to="/" className={`mobile-menu-link ${location.pathname === "/" ? "mobile-menu-link-active" : ""}`}>
        <SlIcon name="card-list"></SlIcon>
        <span>Materias</span>
      </Link>
    </div>
  );
};

export default MobileMenu;
